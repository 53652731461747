<template>
  <b-sidebar id="add-new-laboratory-sidebar" :visible="isAddNewLaboratorySidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-laboratory-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <h5 class="mb-0">{{ $t("Add") }} {{ $t("New") }} Type Laboratory</h5>

        <i class="fas fa-times ml-1 cursor-pointer" @click="hide"></i>
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Full Name -->
          <validation-provider #default="validationContext" name="name" rules="required">
            <b-form-group :label="$t('Form.Name')" label-for="name">
              <b-form-input id="name" v-model="stateData.name" autofocus :state="getValidationState(validationContext)"
                trim placeholder="Type Laboratory name" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- code -->
          <validation-provider #default="validationContext" name="code" rules="required">
            <b-form-group label="Code" label-for="code">
              <b-form-input id="code" v-model="stateData.code" autofocus :state="getValidationState(validationContext)"
                trim number placeholder="code " />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- price -->
          <validation-provider #default="validationContext" name="price" rules="required">
            <b-form-group label="Price" label-for="price">
              <b-form-input id="price" v-model="stateData.price" autofocus :state="getValidationState(validationContext)"
                trim placeholder="$$$" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- price -->
          <validation-provider #default="validationContext" name="price cost" rules="required">
            <b-form-group label="Price cost" label-for="price">
              <b-form-input id="price" v-model="stateData.price_cost" autofocus
                :state="getValidationState(validationContext)" trim placeholder="$$$" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- category -->
          <validation-provider #default="validationContext" name="Category" rules="required">
            <b-form-group label="Category" label-for="category">
              <treeselect id="category" v-model="stateData.categories" :defaultOptions="true"
                :state="getValidationState(validationContext)" :async="true" :load-options="searchCategory"
                :normalizer="normalizer" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- status -->
          <validation-provider #default="validationContext" name="Status" rules="required">
            <b-form-group :label="$t('Form.Status')" label-for="status">
              <treeselect id="status" v-model="stateData.status" :state="getValidationState(validationContext)"
                :options="optionsStatus" :normalizer="normalizer" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" type="submit" :disabled="loading">
              <span v-if="!loading">{{ $t("Add") }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button type="button" variant="outline-danger" @click="hide">
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import formValidation from "@/core/utils/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";
import axiosACL from "@/core/services/apiInsurance/admin/consults/laboratoryCategories";

import { required } from "@validations";
import { ref } from "@vue/composition-api";

import axiosCT from "@/core/services/apiInsurance/admin/consults/laboratories";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: "isAddNewLaboratorySidebarActive",
    event: "update:is-add-new-laboratory-sidebar-active",
  },
  props: {
    isAddNewLaboratorySidebarActive: {
      type: Boolean,
      required: true,
    },
    itemsCategories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  setup(props, { emit }) {
    const optionsStatus = [
      { name: "Active", id: true },
      { name: "Inactive", id: false },
    ];
    const stateDataTem = {
      name: "",
      status: true,
    };
    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)));

    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(stateDataTem));
    };
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetuserData
    );

    const searchCategory = ({ action, searchQuery, callback }) => {
      if (action === "ASYNC_SEARCH") {
        axiosACL.categoryLabList(50).then(({ data }) => {
          callback(null, data);
        });
      }
    };

    const loading = ref(false);
    const onSubmit = () => {
      loading.value = true;
      const datos = {
        ...stateData.value,
      };
      axiosCT
        .laboratoryCreate(datos)
        .then(({ registro }) => {
          loading.value = false;
          resetuserData();
          emit("createLaboratory", { status: true, registro });
        })
        .catch(() => {
          loading.value = false;
          emit("createLaboratory", { status: false });
        });
    };
    return {
      loading,
      stateData,
      optionsStatus,
      searchCategory,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style></style>
